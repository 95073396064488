.appbar {
    display: flex;
    align-items: center;
    gap: 1rem;
    position: relative;
    z-index: 3;
}

.appbar form {
    display: flex;
    align-items: center;
    padding: 0.75rem 1.5rem;
    background-color: var(--primary-500);
    width: 50%;
    max-width: 500px;
    border-radius: 10px;
    gap: 1rem;
    position: relative;
}

.appbar form button {
    width: 20px;
    cursor: pointer;
}

.appbar form input {
    color: white;
    width: 85%;
}

.appbar__profile {
    display: flex;
    gap: 1rem;
    align-items: center;
    margin-left: auto;
}

.appbar__profile__dp {
    width: 40px;
    aspect-ratio: 1;
    object-fit: cover;
    border-radius: 50%;
}

.appbar__profile a {
    height: 40px;
}

.appbar__profile__logout {
    width: 22px;
    margin-bottom: 5px;
}

.appbar__profile__home {
    width: 22px;
    margin-bottom: 5px;
    filter: invert(48%) sepia(79%) saturate(10%) hue-rotate(86deg) brightness(118%) contrast(119%);
}

.appbar .close {
    transform: scale(1.5);
    aspect-ratio: 1;
}

.hamburger {
    display: none;
    width: 22px;
}

.hamburger>img {
    width: 100%;
}

.appbar__profile .chat {
    height: 85%;
}

.appbar a {
    display: flex;
    align-items: center;
}

@media (max-width: 800px) {
    .appbar form {
        padding: 0.75rem;
        flex: 1;
    }

    .hamburger {
        display: flex;
        align-items: center;
    }

    .appbar__profile {
        gap: 1rem;
    }
}