@import url(https://fonts.googleapis.com/css2?family=Poppins:wght@200;300;400;500;600;700&family=Roboto:wght@300;400&display=swap);
:root {
    --primary-400: #0b0c27;
    --primary-500: #191a35;
    --primary-600: #2b2c44;
}

*,
*::after,
*::before {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    -webkit-tap-highlight-color: transparent;
}

body {
    font-family: poppins, sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    background-color: #0b0c27;
    background-color: var(--primary-400);
}

img {
    max-width: 100%;
    display: block;
}

button,
input,
textarea {
    border: none;
    outline: none;
    font-family: inherit;
    background: none;
}

button,
a {
    cursor: pointer;
    text-decoration: none;
    color: inherit;
}

.roundimage {
    aspect-ratio: 1;
    border-radius: 50%;
    object-fit: cover;
}

.container {
    max-width: 1700px;
    height: 100vh;
    min-height: 750px;
    padding: 2rem;
    margin-inline: auto;
    display: flex;
    flex-direction: column;
    grid-gap: 2rem;
    gap: 2rem;
}

button:disabled {
    cursor: not-allowed;
}

input::-webkit-input-placeholder {
    font-size: 0.9rem;
}

input:-ms-input-placeholder {
    font-size: 0.9rem;
}

input::placeholder {
    font-size: 0.9rem;
}

.green {
    position: relative;
}

.sidebar {
    position: absolute;
    width: 400px;
    opacity: 0;
    z-index: 999;
    border-radius: 10px;
    top: 100px;
    bottom: 10px;
    left: -40px;
    transition: all 0.2s linear;
    pointer-events: none;
}

.green::after {
    content: "";
    position: absolute;
    width: 30%;
    aspect-ratio: 1;
    background-color: rgb(21, 214, 31);
    border-radius: 50%;
    right: 0;
    bottom: 0;
}

@media (max-width: 800px) {
    .container {
        padding: 1rem 0.75rem;
        max-width: 500px;
    }
    .visible {
        left: 0;
        opacity: 1;
        pointer-events: all;
        box-shadow: 0 10px 100px rgb(0, 0, 0);
    }
}

.input__box {
    background: var(--primary-400);
    width: 100%;
    padding: 0.75rem 1rem;
    border-radius: 10px;
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.input__box > input {
    color: white;
    width: 85%;
}

.input__box button img {
    height: 1rem;
    cursor: pointer;
}

.comment {
    display: flex;
    align-items: flex-start;
    grid-gap: 0.5rem;
    gap: 0.5rem;
    color: white;
    margin-bottom: 2rem;
}

.comment__dp {
    width: 2.5rem;
    aspect-ratio: 1;
    object-fit: cover;
    border-radius: 50%;
    position: relative;
    flex-shrink: 0;
}

.comment h3 {
    font-size: 0.85rem;
    font-weight: 500;
}

.comment p {
    font-size: 0.75rem;
    opacity: 0.5;
}

.comments {
   overflow: auto;
   margin-bottom: 1.5rem;
   color: white;
   height: 100%;
   scrollbar-width: none; /*to work in moz*/
}

.comments > h3 {
   margin-bottom: 2rem;
   font-size: 0.9rem;
}

.comments::-webkit-scrollbar {
   display: none;
}

h3 {
    font-size: 0.9rem;
    font-weight: 500;
    margin-bottom: 0.2em;
}

p {
    font-size: 0.9rem;
}

.post header p {
    font-size: 0.7rem;
    margin-top: -3px;
    opacity: 0.4;
}

.post {
    background: var(--primary-500);
    padding: 2rem 1.5rem;
    color: white;
    border-radius: 10px;
    display: flex;
    flex-direction: column;
    grid-gap: 1rem;
    gap: 1rem;
}

.halfborder {
    border-radius: 10px 0 0 10px;
}

.post__dp {
    width: 2.5rem;
}

.post > header {
    display: flex;
    align-items: flex-start;
    grid-gap: 0.5rem;
    gap: 0.5rem;
}

.post__details {
    display: flex;
    flex-direction: column;
    grid-gap: 0.75rem;
    gap: 0.75rem;
}

.post__image {
    max-height: 450px;
    width: 100%;
    border-radius: 10px;
    object-fit: contain;
    background-color: var(--primary-600);
}

.post__footer {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 0.5rem;
}

.post__reactions {
    display: flex;
    align-items: center;
    grid-gap: 1rem;
    gap: 1rem;
    width: 50%;
    flex-shrink: 0;
}

.post__reactions img {
    height: 25px;
    cursor: pointer;
}

.post__reactions p {
    position: relative;
    top: 1.5px;
    font-size: 1rem;
    font-weight: 400;
}

@media (max-width: 1100px) {
    .post__reactions {
        width: 25%;
    }
}

@media (max-width: 800px) {
    .halfborder {
        border-radius: 10px;
    }
    .post {
        padding: 1rem;
    }
}

.options {
   margin-left: auto;
   cursor: pointer;
   position: relative;
   height: 40px;
   aspect-ratio: 1;
   display: flex;
   align-items: center;
   justify-content: center;
   border-radius: 50%;
}

.options:hover {
   background-color: var(--primary-400);
}

.options ul {
   list-style: none;
   background-color: var(--primary-400);
   position: absolute;
   border-radius: 10px;
   right: 10px;
   top: 110%;
   overflow: hidden;
   opacity: 0;
   pointer-events: none;
   transition: opacity 0.1s linear;
}

ul.show {
   opacity: 1;
   pointer-events: all;
}

.options ul li {
   padding: 0.5rem 1rem;
   font-size: 0.8rem;
}

.options ul li:hover {
   background-color: var(--primary-600);
}

.singlepost {
    display: flex;
    grid-gap: 2px;
    gap: 2px;
    height: 100%;
}

.singlepost__left {
    flex: 3 1;
    background-color: var(--primary-500);
    border-radius: 10px 0 0 10px;
}

.singlepost__comments {
    border-radius: 0 10px 10px 0;
    flex: 1 1;
    background-color: var(--primary-500);
    position: relative;
}

.singlepost__comments>div {
    position: absolute;
    inset: 0;
    padding: 2rem;
    display: flex;
    flex-direction: column;
}

/* .singlepost__right {
    flex: 1;
    position: relative;
    margin-left: 1rem;
    overflow: auto;
} */

@media (max-width: 1200px) {
    .singlepost__right {
        display: none;
    }
}

@media (max-width: 800px) {
    .singlepost {
        min-height: auto;
        grid-gap: 1rem;
        gap: 1rem;
        flex-direction: column;
        height: auto;
    }

    .singlepost__left {
        border-radius: 10px;
    }

    .singlepost__comments {
        flex: initial;
        border-radius: 10px;
    }

    .singlepost__comments>div {
        padding: 2rem 1rem;
        position: relative;
    }
}
.appbar {
    display: flex;
    align-items: center;
    grid-gap: 1rem;
    gap: 1rem;
    position: relative;
    z-index: 3;
}

.appbar form {
    display: flex;
    align-items: center;
    padding: 0.75rem 1.5rem;
    background-color: var(--primary-500);
    width: 50%;
    max-width: 500px;
    border-radius: 10px;
    grid-gap: 1rem;
    gap: 1rem;
    position: relative;
}

.appbar form button {
    width: 20px;
    cursor: pointer;
}

.appbar form input {
    color: white;
    width: 85%;
}

.appbar__profile {
    display: flex;
    grid-gap: 1rem;
    gap: 1rem;
    align-items: center;
    margin-left: auto;
}

.appbar__profile__dp {
    width: 40px;
    aspect-ratio: 1;
    object-fit: cover;
    border-radius: 50%;
}

.appbar__profile a {
    height: 40px;
}

.appbar__profile__logout {
    width: 22px;
    margin-bottom: 5px;
}

.appbar__profile__home {
    width: 22px;
    margin-bottom: 5px;
    -webkit-filter: invert(48%) sepia(79%) saturate(10%) hue-rotate(86deg) brightness(118%) contrast(119%);
            filter: invert(48%) sepia(79%) saturate(10%) hue-rotate(86deg) brightness(118%) contrast(119%);
}

.appbar .close {
    -webkit-transform: scale(1.5);
            transform: scale(1.5);
    aspect-ratio: 1;
}

.hamburger {
    display: none;
    width: 22px;
}

.hamburger>img {
    width: 100%;
}

.appbar__profile .chat {
    height: 85%;
}

.appbar a {
    display: flex;
    align-items: center;
}

@media (max-width: 800px) {
    .appbar form {
        padding: 0.75rem;
        flex: 1 1;
    }

    .hamburger {
        display: flex;
        align-items: center;
    }

    .appbar__profile {
        grid-gap: 1rem;
        gap: 1rem;
    }
}
.auth {
   -webkit-perspective: 1200px;
           perspective: 1200px;
   height: 100vh;
   display: flex;
   align-items: center;
   justify-content: center;
   padding: 1rem;
}

.login,
.register {
   position: absolute;
   left: 50%;
   top: 50%;
   -webkit-backface-visibility: hidden;
           backface-visibility: hidden;
   -webkit-transform-style: preserve-3d;
           transform-style: preserve-3d;
   transition: -webkit-transform 0.4s linear;
   transition: transform 0.4s linear;
   transition: transform 0.4s linear, -webkit-transform 0.4s linear;
   -webkit-transform: translate(-50%, -50%);
           transform: translate(-50%, -50%);
}

.register {
   -webkit-transform: translate(-50%, -50%) rotateY(-180deg);
           transform: translate(-50%, -50%) rotateY(-180deg);
}

.signup .login {
   -webkit-transform: translate(-50%, -50%) rotateY(180deg);
           transform: translate(-50%, -50%) rotateY(180deg);
}

.signup .register {
   -webkit-transform: translate(-50%, -50%) rotateY(0deg);
           transform: translate(-50%, -50%) rotateY(0deg);
}

.auth p {
   margin-inline: auto;
   width: -webkit-max-content;
   width: max-content;
   margin-top: 1.5rem;
}

.auth form {
   background-color: var(--primary-500);
   color: white;
   width: 400px;
   padding: 3rem 1.5rem 1.5rem 1.5rem;
   border-radius: 10px;
}

.auth form > * {
   display: block;
   margin-bottom: 0.5rem;
}

.auth label {
   padding-left: 0.3rem;
   font-weight: 300;
   font-size: 0.85rem;
}

.auth input {
   background-color: var(--primary-600);
   width: 100%;
   padding: 0.75rem 1rem;
   color: white;
   border-radius: 10px;
   margin-bottom: 2rem;
}

.auth button {
   background: var(--primary-400);
   width: 100%;
   color: white;
   padding: 0.75rem;
   border-radius: 10px;
   font-size: 1rem;
}

.auth p span:hover {
   cursor: pointer;
   text-decoration: underline;
}

@media (max-width: 420px) {
   .auth form {
      width: 350px;
   }
}

.modal {
   padding: 0.5rem 1rem;
   background-color: white;
   color: var(--primary-400);
   left: 50%;
   -webkit-transform: translate(-50%, -120%);
           transform: translate(-50%, -120%);
   position: absolute;
   top: 0;
   transition: -webkit-transform 0.1s linear;
   transition: transform 0.1s linear;
   transition: transform 0.1s linear, -webkit-transform 0.1s linear;
   border-radius: 10px;
   text-align: center;
   z-index: 99;
}

.modal p {
   font-size: 0.9rem;
}

.modal.visible {
   -webkit-transform: translate(-50%, 50px);
           transform: translate(-50%, 50px);
}

.createpost {
    background-color: var(--primary-500);
    padding: 2rem 1.5rem;
    border-radius: 10px;
}

.createpost textarea {
    box-sizing: border-box;
    width: 100%;
    height: 100px;
    resize: none;
    color: white;
    font-size: 1rem;
    background-color: var(--primary-600);
    border-radius: 10px;
    padding: 1rem;
}

.createpost button,
label div {
    background-color: rgba(255, 255, 255, 0.158);
    width: 50px;
    aspect-ratio: 1;
    border-radius: 50%;
    position: relative;
}

input[type="file"] {
    display: none;
}

label div img,
.createpost button img {
    height: 20px;
    position: absolute;
    left: 50%;
    top: 50%;
    -webkit-transform: translate(-45%, -50%);
            transform: translate(-45%, -50%);
}

label div {
    cursor: pointer;
}

.btns {
    margin-top: 1rem;
    display: flex;
    grid-gap: 1rem;
    gap: 1rem;
    float: right;
}

.uploaded-image {
    width: 100%;
    border-radius: 10px;
}

@media (max-width: 800px) {
    .createpost {
        padding: 1rem;
    }
}

.home {
    width: 100%;
    height: 100%;
    max-width: 1200px;
    margin-inline: auto;
    display: flex;
    grid-gap: 2rem;
    gap: 2rem;
    overflow: hidden;
}

.home__left,
.home__right {
    position: relative;
    display: flex;
    flex-direction: column;
    grid-gap: 1rem;
    gap: 1rem;
}

.home__left {
    flex: 1 1;
    overflow: auto;
    scrollbar-width: none; /*to work in moz*/
}

/* .home__right {
    flex: 1;
} */

.home__left::-webkit-scrollbar {
    display: none;
}

@media (max-width: 700px) {
    .home__right {
        display: none;
    }
}

.notfound {
   margin-top: 7rem;
   max-width: 600px;
   margin-inline: auto;
   display: flex;
   flex-direction: column;
   grid-gap: 1rem;
   gap: 1rem;
   align-items: center;
   color: white;
}

.notfound > img {
   object-fit: contain;
   margin-bottom: 1rem;
}
.notfound h1 {
   font-size: 3rem;
   font-weight: 500;
}

.notfound button {
   padding: 1em 2em;
   border-radius: 10px;
   background-color: var(--primary-500);
}

.loading {
   position: absolute;
   inset: 0;
   background-color: rgba(8, 8, 24, 0.884);
   z-index: 15;
   display: flex;
   flex-direction: column;
   align-items: center;
   justify-content: center;
   color: white;
   grid-gap: 1em;
   gap: 1em;
   text-align: center;
}

.loading__circle {
   width: 50px;
   aspect-ratio: 1;
   border-radius: 50%;
   border: 5px solid white;
   border-bottom-color: transparent;
   -webkit-transform-origin: center;
           transform-origin: center;
   -webkit-animation: rotate 1s linear infinite;
           animation: rotate 1s linear infinite;
   margin-bottom: 2rem;
}

.loading p {
   font-size: 0.9rem;
   max-width: 600px;
   line-height: 1.8em;
}

@-webkit-keyframes rotate {
   to {
      -webkit-transform: rotate(360deg);
              transform: rotate(360deg);
   }
}

@keyframes rotate {
   to {
      -webkit-transform: rotate(360deg);
              transform: rotate(360deg);
   }
}

