.loading {
   position: absolute;
   inset: 0;
   background-color: rgba(8, 8, 24, 0.884);
   z-index: 15;
   display: flex;
   flex-direction: column;
   align-items: center;
   justify-content: center;
   color: white;
   gap: 1em;
   text-align: center;
}

.loading__circle {
   width: 50px;
   aspect-ratio: 1;
   border-radius: 50%;
   border: 5px solid white;
   border-bottom-color: transparent;
   transform-origin: center;
   animation: rotate 1s linear infinite;
   margin-bottom: 2rem;
}

.loading p {
   font-size: 0.9rem;
   max-width: 600px;
   line-height: 1.8em;
}

@keyframes rotate {
   to {
      transform: rotate(360deg);
   }
}
