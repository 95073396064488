.home {
    width: 100%;
    height: 100%;
    max-width: 1200px;
    margin-inline: auto;
    display: flex;
    gap: 2rem;
    overflow: hidden;
}

.home__left,
.home__right {
    position: relative;
    display: flex;
    flex-direction: column;
    gap: 1rem;
}

.home__left {
    flex: 1;
    overflow: auto;
    scrollbar-width: none; /*to work in moz*/
}

/* .home__right {
    flex: 1;
} */

.home__left::-webkit-scrollbar {
    display: none;
}

@media (max-width: 700px) {
    .home__right {
        display: none;
    }
}
